import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 22; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/21-sep-4/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function GrandparentsDayCelebration2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Grandparents' Day Celebration</h1>

        <p>
          <strong>
            “Grandparents are a family's greatest treasure and the footsteps to
            the future generations.”
          </strong>
          <br />
          <br />
          To honour and thank the beloved grandparents for their unconditional
          love, the learners of classes I-II and Pre- Primary Department of
          Chinmaya Vidyalaya, New Delhi celebrated Grandparents' Day – Grand
          Masti with Grandparents.
          <br />
          <br />
          Our learners of classes I and II welcomed the grandparents with the
          auspicious tilak ceremony. This was followed by a myriad of joyful
          activities, music, dance and creative craft. Our munchkins were
          fascinated by the riveting stories told by the greatest storytellers,
          the grandparents. They lent a helping hand to their Nanis and Dadis
          while preparing finger-licking and healthy recipes as part of the
          non-fire cooking activity.
          <br />
          <br />
          The learners in Pre- Primary Department enjoyed story time with their
          grandparents wherein they learnt the lessons about being kind and
          good. They also enjoyed dancing with their grandparents.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
